import clsx from 'clsx';
import React from 'react';

import { EButtonSize, EButtonTheme, EButtonVariant } from '../../atoms/Button';
import Button from '../../atoms/Button/Button';
import { LibraryCard } from '../../atoms/LibraryCard';

import type { TNewsCardProps } from '../../atoms/NewsCard/NewsCard';

export type TSpaceXlTabBody = {
  tabPosts?: TNewsCardProps[];
  buttons?: React.ComponentProps<typeof Button>[];
};

export const TabBody: React.FC<TSpaceXlTabBody> = ({
  tabPosts = [],
  buttons,
}) => {
  if (tabPosts.length === 0) {
    return null;
  }

  return (
    <div className='flex flex-row flex-wrap gap-2 tablet:gap-4 desktop:grid desktop:grid-cols-4'>
      {tabPosts.map((post, index) => (
        <LibraryCard
          className={clsx(
            index <= 2 ? 'flex' : 'hidden',
            index <= 4 ? 'tablet:flex' : 'tablet:hidden',
            index <= 6 ? 'desktop:flex' : 'desktop:hidden',
          )}
          key={index}
          {...post}
        />
      ))}
      {buttons && buttons.length !== 0 && (
        <>
          {buttons.map((button, index) => (
            <Button
              {...button}
              key={index}
              variant={EButtonVariant.Text}
              theme={EButtonTheme.Control}
              size={EButtonSize.Large}
              className={clsx(
                'flex min-h-[296px] min-w-[236px] max-w-[488px] shrink grow basis-[236px] items-center justify-center rounded-3xl border-2 border-control-200 tablet:p-8 tablet:max-desktop:max-w-[calc(50%-8px)]',
                'font-normal leading-relaxed text-interface-700 transition-opacity hover:opacity-80',
              )}
            >
              {button.children}
            </Button>
          ))}
        </>
      )}
    </div>
  );
};
